// **************************************************
// Quiz Screen
// **************************************************

@import '../../../scss/import';

.quiz-screen {
	justify-content: center;
	position: relative;
	padding: 100px 0 60px;

	// Tablet L
	@media #{$breakpoint-md-only} {
		padding-top: 200px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding-top: 140px;
	}

	// ##################################################

	.image-aspect {
		opacity: 0.2;
	}

	// ##################################################

	&__inner {
		flex-direction: column;
	}

	// ##################################################

	.challenge-popup {
		position: absolute;
		bottom: 68px;
		right: 28px;
		z-index: $zindex_modal;

		// Tablet L and Down
		@media #{$breakpoint-md-down} {
			bottom: 60px;
			right: 20px;
		}

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			bottom: 20px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 570px;
			position: relative;
			bottom: 0;
			right: 0;
			margin-top: 20px;
		}
	}
}
