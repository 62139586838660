// **************************************************
// Banner
// **************************************************

@import '../../../scss/import';

.banner {
	min-height: 638px;
	color: $cgp_white;
	display: flex;
	align-items: center;
	justify-content: center;

	// Mobile
	@media #{$breakpoint-xs-only} {
		min-height: $vh_100;
	}

	// ##################################################

	&::before {
		@extend %content;
		z-index: 1;
		background-color: rgba($cgp_blue_dark, 0.8);
	}

	// ##################################################

	.container {
		position: relative;
		z-index: 9;

		// Tablet L and up
		@media #{$breakpoint-md-up} {
			max-width: 860px;
		}
	}

	// ##################################################

	&__backtoschool-image {
		margin: 0 0 24px -25px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin: 0 0 10px;
			max-width: 250px;
		}
	}

	// ##################################################

	&__content {
		max-width: 515px;
		width: 100%;
		padding: 76px 0 30px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding-bottom: 20px;
		}
	}

	// ##################################################

	.title {
		margin-bottom: 5px;
		line-height: 1.08;
	}

	// ##################################################

	&__link {
		margin-top: 20px;
		display: inline-block;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 40px;
		}

		a {
			text-decoration: underline;

			&:hover {
				color: $cgp_bermuda;
			}
		}
	}

	// ##################################################

	&__button {
		margin-top: 32px;

		.btn {
			font-size: 14px;
			min-width: 1px;
			min-height: 40px;
			padding: 6px 20px;
		}
	}

	// ##################################################

	&__logos {
		display: grid;
		grid-template-columns: 128px 150px;
		grid-gap: 56px;
		margin-bottom: 50px;
		align-items: center;

		// Tablet L and Down
		@media #{$breakpoint-md-down} {
			margin-bottom: 30px;
		}

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			grid-template-columns: 128px 128px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			grid-gap: 40px;
		}

		// Mobile xs
		@media (max-width: 360px) {
			grid-template-columns: repeat(2, 100px);
		}
	}

	// ##################################################

	&__logo {
		position: relative;

		// ##################################################

		&:not(:first-child) {
			// ##################################################
			// Left Border

			&::before {
				content: '';
				position: absolute;
				left: -32px;
				top: 50%;
				transform: translateY(-50%);
				height: 35px;
				width: 1px;
				background-color: $cgp_white;

				// Tablet P and Down
				@media #{$breakpoint-sm-down} {
					height: 25px;
				}

				// Mobile
				@media #{$breakpoint-xs-only} {
					left: -20px;
				}

				// Mobile xs
				@media (max-width: 360px) {
					height: 20px;
				}
			}
		}
	}
}
