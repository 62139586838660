// ##################################################
// 0.0 Icons
// ##################################################

@import '../../../scss/import';

// General Icons
.icon {
	// background: transparent no-repeat scroll center / contain;
	display: block;
	position: relative;

	// Icons for hover effect
	&::before {
		background-color: var(--icon-color, $cgp_blue);
		-webkit-mask-size: contain;
		mask-size: contain;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		-webkit-mask-position: center;
		mask-position: center;
		@extend %content;
		transition: background-color 0.3s ease;
	}

	// ##################################################

	&-arrow-right,
	&-arrow-left {
		width: 10px;
		height: 10px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$arrow_right}');
			mask-image: url('data:image/svg+xml,#{$arrow_right}');
		}
	}

	// ##################################################

	&-chevron-right,
	&-chevron-left {
		width: 8px;
		height: 12px;

		&::before {
			-webkit-mask-image: url('data:image/svg+xml,#{$chevron_right}');
			mask-image: url('data:image/svg+xml,#{$chevron_right}');
		}
	}

	// ##################################################

	&-chevron-up,
	&-chevron-down {
		width: 10px;
		height: 8px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$chevron_down}');
			mask-image: url('data:image/svg+xml,#{$chevron_down}');
		}
	}

	// ##################################################

	&-chevron-up,
	&-chevron-left,
	&-arrow-left {
		&:before {
			@include rotate(180deg);
		}
	}

	// ##################################################

	&-shape-square {
		width: 34px;
		height: 34px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$shape_square}');
			mask-image: url('data:image/svg+xml,#{$shape_square}');
		}
	}

	// ##################################################

	&-shape-snake {
		width: 56px;
		height: 56px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$shape_snake}');
			mask-image: url('data:image/svg+xml,#{$shape_snake}');
		}
	}

	// ##################################################

	&-shape-circle {
		width: 37px;
		height: 37px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$shape_circle}');
			mask-image: url('data:image/svg+xml,#{$shape_circle}');
		}
	}

	// ##################################################

	&-shape-triangle {
		width: 38px;
		height: 33px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$shape_triangle}');
			mask-image: url('data:image/svg+xml,#{$shape_triangle}');
		}
	}

	// ##################################################

	&-shape-cross {
		width: 20px;
		height: 20px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$shape_cross}');
			mask-image: url('data:image/svg+xml,#{$shape_cross}');
		}
	}

	// ##################################################

	&-shape-circle-small {
		width: 18px;
		height: 18px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$shape_circle_small}');
			mask-image: url('data:image/svg+xml,#{$shape_circle_small}');
		}
	}

	// ##################################################

	&-close {
		width: 12px;
		height: 12px;

		&:before {
			-webkit-mask-image: url('data:image/svg+xml,#{$icon_close}');
			mask-image: url('data:image/svg+xml,#{$icon_close}');
		}
	}
}
