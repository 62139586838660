// **************************************************
// Footer
// **************************************************

@import '../../../scss/import';

.footer-main {
	position: relative;
	width: 100%;
	color: $cgp_blue;
	background-color: $cgp_gray_light;

	// ##################################################

	&__inner {
		padding: 50px 60px 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		// Tablet L
		@media #{$breakpoint-md-only} {
			padding: 40px 20px;
		}

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			padding: 30px 20px;
			flex-direction: column;
			align-items: flex-start;
		}
	}

	// ##################################################

	&__logo {
		margin-right: 16px;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			margin-bottom: 20px;
			width: 100%;
			flex: auto;
		}

		// ##################################################

		a {
			display: block;
			max-width: 70px;
			width: 100%;
		}
	}

	// ##################################################

	&__item {
		display: flex;
		align-items: center;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			flex-wrap: wrap;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			flex-direction: column;
			align-items: flex-start;
			flex: auto;
			width: 100%;
		}

		// ##################################################

		&:not(:last-child) {
			margin-right: 30px;

			// Tablet P and Down
			@media #{$breakpoint-sm-down} {
				margin: 0 0 20px;
			}
		}
	}

	// ##################################################

	&__title {
		font-weight: 700;
		color: $cgp_blue;
		text-transform: uppercase;
		margin-right: 18px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin: 0 0 10px;
		}
	}

	// ##################################################

	&__nav {
		&-item {
			display: inline-block;

			// Mobile
			@media #{$breakpoint-xs-only} {
				display: block;
			}

			// ##################################################

			&:not(:last-child) {
				margin-right: 18px;

				// Mobile
				@media #{$breakpoint-xs-only} {
					margin: 0 0 10px;
				}
			}
		}

		// ##################################################

		&-link {
			&:hover {
				color: $cgp_pink;
			}
		}
	}
}
