// **************************************************
// Thankyou Box
// **************************************************

@import '../../../scss/import';

.thankyou-box {
	max-width: 570px;
	padding: 72px 45px 30px;
	position: relative;
	text-align: center;

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 62px 25px 30px;
	}

	// ###########################################################

	&__image {
		max-width: 376px;
		width: 100%;
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translate(-50%, -50%);

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 280px;
		}

		// Mobile xs
		@media (max-width: 360px) {
			max-width: 210px;
		}
	}

	// ###########################################################

	&__illustrator-image {
		max-width: 168px;
		width: 100%;
		margin: 22px auto 0;

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 140px;
		}

		// Mobile xs
		@media (max-width: 360px) {
			max-width: 100px;
		}
	}

	// ###########################################################

	.editor-text {
		margin-top: 4px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			font-size: 16px;
		}

		// ###########################################################

		span {
			color: $cgp_pink;
		}
	}

	// ###########################################################

	.btn {
		margin-top: 16px;
	}

	// ##################################################

	&__logo {
		max-width: 170px;
		width: 100%;
		margin: 30px auto 0;

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 150px;
			margin-top: 20px;
		}
	}
}
