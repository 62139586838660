// **************************************************
// Challenge Popup
// **************************************************

@import '../../../scss/import';

.challenge-popup {
	max-width: 255px;
	width: 100%;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	border-radius: 9px;
	background-color: $cgp_bermuda;
	position: relative;
	padding: 14px 18px 18px 18px;
	transition: opacity 0.3s ease, transform 0.3s ease;

	// stylelint-disable-next-line
	&.#{$class_hidden} {
		opacity: 0;
		pointer-events: none;
		transform: scale(0.9);

		// Mobile
		@media #{$breakpoint-xs-only} {
			display: none;
		}
	}

	// ###########################################################

	&__header {
		padding-right: 30px;
		margin-bottom: 14px;
	}

	// ###########################################################

	&__close {
		position: absolute;
		right: 18px;
		top: 20px;

		&:hover {
			i::before {
				background-color: $cgp_white;
			}
		}
	}

	// ###########################################################

	form {
		margin-top: 10px;
	}

	// ###########################################################

	.editor-text {
		line-height: 1.36;
	}

	// ###########################################################

	.mod-input {
		border: 1px solid $cgp_white;
		height: 40px;
		border-radius: 20px;
		width: 100%;
		color: $cgp_white;

		// ###########################################################

		&__field {
			padding: 8px 14px;
			color: $cgp_white;

			// ###########################################################

			// stylelint-disable-next-line
			.#{$class_validation_failed} &,
			&.#{$class_validation_failed} {
				color: $cgp_validation_failed;
			}
		}

		// ###########################################################

		&__label {
			top: 10px;
			left: 14px;
		}

		// ###########################################################

		// stylelint-disable-next-line
		&.#{$class_validation_failed} {
			border-color: $cgp_validation_failed;
		}
	}

	// ###########################################################

	.btn {
		min-width: 1px;
		min-height: 1px;
		font-size: 14px;
		line-height: normal;
		padding: 6px 25px;
		border: 1px solid $cgp_pink;
	}
}
