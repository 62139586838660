// **************************************************
// Top 10
// **************************************************

@import '../../../scss/import';

.top-10 {
	padding: 36px 0 100px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 30px 0 60px;
	}

	// ##################################################

	&__inner {
		padding: 38px;
		position: relative;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 24px;
		}
	}

	// ##################################################

	&__logo {
		max-width: 240px;
		width: 100%;
		position: absolute;
		bottom: 50px;
		right: 52px;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			max-width: 170px;
			right: 20px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			position: relative;
			bottom: 0;
			right: 0;
			margin: 20px auto 0;
		}

		// ##################################################

		img {
			width: 100%;
		}
	}

	// ##################################################

	.title {
		margin-bottom: 25px;
	}

	// ##################################################

	&__header {
		margin-bottom: 38px;
		max-width: 492px;

		.title {
			margin-bottom: 6px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-bottom: 10px;
			}
		}
	}

	// ##################################################

	&__table {
		width: 100%;
		max-width: 444px;
		margin-bottom: 14px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			min-width: 280px;
		}

		// ##################################################

		&-wrapper {
			// Mobile
			@media #{$breakpoint-xs-only} {
				width: 100%;
				overflow: auto;
			}
		}

		// ##################################################

		&-header {
			color: $cgp_pink;
			font-weight: 700;
			margin-bottom: 20px;
		}

		// ##################################################

		&-srno {
			width: 32px;
			border-radius: 100%;
			height: 32px;
			border: 1px solid $cgp_blue_dark;
			color: $cgp_pink;
			font-size: 15px;
			font-weight: 700;

			// Mobile
			@media #{$breakpoint-xs-only} {
				width: 28px;
				height: 28px;
				font-size: 14px;
			}
		}

		// ##################################################

		&-row {
			align-items: flex-end;
			display: grid;
			grid-template-columns: 50px 1.5fr 1fr 34px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				grid-template-columns: 40px 1.5fr 1fr 34px;
			}
		}

		// ##################################################

		&-body {
			display: grid;
			grid-gap: 8px;

			// ##################################################

			.top-10__table-col {
				padding: 5px 5px 7px 0;

				&:not(:first-child) {
					border-bottom: 1px dashed $cgp_border;
				}

				&:nth-child(2) {
					font-weight: 700;
				}

				&:first-child {
					padding: 0;
				}
			}
		}
	}

	// ##################################################

	&__shapes {
		i {
			position: absolute;
			right: 0;
			top: 0;
			z-index: 9;

			&:nth-child(1) {
				top: -69px;
				right: 9%;
			}

			&:nth-child(2) {
				top: 31px;
				right: 19%;
			}

			&:nth-child(3) {
				top: 81px;
				right: -1%;
			}

			&:nth-child(4) {
				top: 178px;
				right: 27%;
			}

			&:nth-child(5) {
				top: 211px;
				right: 6%;
			}

			&:nth-child(6) {
				top: 272px;
				right: 22%;
			}

			&:nth-child(7) {
				top: 362px;
				right: 18%;
				transform: rotate(65deg);
			}
		}

		.icon-shape-cross,
		.icon-shape-triangle,
		.icon-shape-square {
			animation: spin 6s linear infinite;
		}

		.icon-shape-triangle {
			animation-duration: 8s;
			animation-delay: 0.2s;
		}

		.icon-shape-square {
			animation-delay: 0.4s;
			animation-duration: 10s;
		}
	}

	// ##################################################

	.container {
		// Tablet L and up
		@media #{$breakpoint-md-up} {
			max-width: 860px;
		}
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
