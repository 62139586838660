//*******************************************************************************
// PLACEHOLDERS
//*******************************************************************************

// General extensions
%content {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	pointer-events: none;
}

// ##################################################

%reset_button {
	background: transparent;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	padding: 0;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
}

// ##################################################

%abs_pos_fullwidth {
	height: 100%;
	left: 0;
	position: absolute;
	width: 100%;
	top: 0;
	pointer-events: none;
}

// ##################################################

%flex_center_center {
	display: flex;
	align-items: center;
	justify-content: center;
}

// ##################################################

%sr_only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	white-space: nowrap;
	border: 0;
	visibility: inherit;
	clip: rect(0, 0, 0, 0);
}

// ##################################################
// FONT SIZES
// ##################################################

// General page text - Paragraph
%page_text {
	font-size: 16px;
	line-height: normal;
}

// ##################################################

%title {
	font-weight: 700;
}

// ##################################################

%title_h1 {
	@extend %title;
	font-size: 30px;
	line-height: 1.13;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 24px;
		line-height: 1.2;
	}
}

// ##################################################

%title_h2 {
	@extend %title;
	font-size: 26px;
	line-height: 1.3;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 22px;
		line-height: 1.2;
	}
}

// ##################################################

%title_h3 {
	@extend %title;
	font-size: 20px;
	line-height: 1;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 18px;
		line-height: 1.3;
	}
}

// ##################################################

%title_h4 {
	@extend %title;
	font-size: 18px;
	line-height: 1.5;
}

// ##################################################

%title_h5 {
	@extend %title;
	font-size: 16px;
	line-height: 1.5;
}

// ##################################################

%title_h6 {
	@extend %title;
	font-size: 14px;
	line-height: 1.5;
}

// ##################################################

%fontsize80 {
	font-size: 80px;
	line-height: 0.35;

	// Tablet P only
	@media #{$breakpoint-sm-only} {
		font-size: 70px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 50px;
	}
}

// ##################################################

%fontsize40 {
	font-size: 40px;
	line-height: 0.85;
	color: $cgp_blue_dark;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 30px;
	}
}


// ##################################################

%fontsize30 {
	@extend %title_h1;
	font-weight: 400;
	line-height: normal;
}

// ##################################################

%fontsize24 {
	font-size: 24px;
	line-height: 1.25;
}

// ##################################################

%fontsize18 {
	font-size: 18px;
	line-height: 1.44;
}

// ##################################################

%fontsize16 {
	font-size: 16px;
	line-height: 1.5;
}

// ##################################################

%fontsize15 {
	font-size: 15px;
	line-height: 1.33;
}

// ##################################################

%fontsize14 {
	font-size: 14px;
	line-height: 1.14;
}

// ##################################################

%fontsize12 {
	font-size: 12px;
	line-height: 1.33;
}

// ##################################################

%inherit_textstyles {
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	text-transform: inherit;
	text-align: inherit;
	font-style: inherit;
	font-family: inherit;
	background-color: transparent;
}

// ##################################################

%backface {
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
}

// ##################################################

%focus_visible {
	outline-offset: 0;
	outline: 2.5px solid $cgp_black;
}

// ##################################################

%bg_video {
	position: absolute;
	min-width: 100%;
	min-height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}
