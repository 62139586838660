// **************************************************
// Site Header
// **************************************************

@import '../../../scss/import';

.header-main {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: $zindex_header;

	// ##################################################

	.container {
		// Tablet L and up
		@media #{$breakpoint-md-up} {
			padding: 0;
			max-width: 100%;
		}
	}

	// ##################################################

	&__logo {
		display: inline-block;
		max-width: 120px;
		position: relative;

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 100px;
		}

		// Mobile xs
		@media (max-width: 360px) {
			max-width: 80px;
		}

		// ##################################################

		&--white {
			display: none;
		}

		// ##################################################

		&:not(:first-child) {
			margin-left: 25px;
			padding-left: 26px;

			// Tablet P and Down
			@media #{$breakpoint-sm-down} {
				margin-left: 15px;
				padding-left: 15px;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-left: 10px;
				padding-left: 10px;
			}

			// ##################################################
			// Left Border

			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				height: 35px;
				width: 1px;
				background-color: $cgp_blue_dark;

				// Tablet P and Down
				@media #{$breakpoint-sm-down} {
					height: 25px;
				}

				// Mobile xs
				@media (max-width: 360px) {
					height: 20px;
				}
			}
		}

		// ##################################################

		&-secondary {
			// Tablet L and Up
			@media #{$breakpoint-md-up} {
				max-width: 155px;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				max-width: 80px;
			}

			// Mobile xs
			@media (max-width: 360px) {
				max-width: 70px;
			}
		}
	}

	// ##################################################

	&__logos {
		display: flex;
		align-items: center;
	}

	// ##################################################

	&__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 12px 36px;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			padding: 15px 0;
		}
	}

	// ##################################################

	&__link {
		color: $cgp_blue;

		// Mobile xs
		@media (max-width: 360px) {
			font-size: 12px;
		}
	}

	// ##################################################

	&--white {
		color: $cgp_white;

		// ##################################################

		.header-main__link {
			color: inherit;
		}

		// ##################################################

		.header-main__inner {
			padding: 32px;

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				padding: 15px 0;
			}
		}

		// ##################################################

		.header-main__logo {
			// Tablet L and Up
			@media #{$breakpoint-md-up} {
				max-width: 155px;
			}

			// ##################################################

			&::before {
				background-color: $cgp_white;
			}

			// ##################################################

			&--dark {
				display: none;
			}

			// ##################################################

			&--white {
				display: block;
			}
		}
	}
}
