// **************************************************
// Radio button
// **************************************************

@import '../../../scss/import';

// Style
.radio-button {
	user-select: none;
	font-size: 15px;
	font-weight: 600;
	min-width: 136px;

	// ##################################################

	&__label {
		position: relative;
		padding: 6px 15px;
		width: 100%;
		border-radius: 24px;
		border: 1px solid $cgp_bermuda;
		cursor: pointer;
		margin: 0;
		text-align: center;
		min-height: 48px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: background-color 0.3s ease, color 0.3s ease;

		&:hover {
			background-color: $cgp_bermuda;
			color: $cgp_white;
		}
	}

	// ##################################################

	input {
		position: absolute;
		width: 1px;
		height: 1px;
		opacity: 0;

		// ##################################################

		&:checked ~ label {
			background-color: $cgp_bermuda;
			color: $cgp_white;
		}

		// ##################################################

		&:focus ~ label {
			background-color: $cgp_bermuda;
			color: $cgp_white;
		}
	}

	// ##################################################

	// stylelint-disable-next-line
	.#{$class_validation_failed} &,
	&.#{$class_validation_failed} {
		// stylelint-disable-next-line
		.radio-button__label {
			border-color: $cgp_validation_failed;
			color: $cgp_validation_failed;
		}
	}
}
