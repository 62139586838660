// **************************************************
// Result Box
// **************************************************

@import '../../../scss/import';

.result-box {
	max-width: 660px;
	padding: 92px 76px 40px;
	position: relative;
	text-align: center;

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 62px 25px 40px;
	}

	// ###########################################################

	&__image {
		max-width: 376px;
		width: 100%;
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translate(-50%, -50%);

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 250px;
		}

		// Mobile xs
		@media (max-width: 360px) {
			max-width: 210px;
		}
	}

	// ###########################################################

	&__result-info {
		display: grid;
		grid-template-columns: repeat(2, 136px);
		grid-gap: 18px;
		margin-top: 32px;
		justify-content: center;

		// Mobile xs
		@media (max-width: 360px) {
			grid-template-columns: 1fr;
			justify-items: center;
		}

		// ###########################################################

		&-item {
			min-height: 95px;
			height: 100%;
			border-radius: 30px;
			border: 1px solid $cgp_pink;
			position: relative;
			background-color: $cgp_white;

			// Mobile xs
			@media (max-width: 360px) {
				max-width: 136px;
				width: 100%;
			}

			// ###########################################################

			&::after {
				@extend %content;
				border-radius: 30px;
				background-color: $cgp_pink;
				right: -5px;
				bottom: -8px;
				left: inherit;
				top: inherit;
			}

			// ###########################################################

			&:nth-child(even) {
				border-color: $cgp_bermuda;

				// ###########################################################

				&::after {
					background-color: $cgp_bermuda;
				}

				// ###########################################################

				.result-box__result-info-title {
					border-color: $cgp_bermuda;
				}
			}
		}

		// ###########################################################

		&-inner {
			position: relative;
			z-index: 4;
			border-radius: 30px;
			height: 100%;
			background-color: $cgp_white;
			display: flex;
			flex-direction: column;
		}

		// ###########################################################

		&-title {
			font-weight: 600;
			padding: 8px 0;
			border-bottom: 1px solid $cgp_pink;
		}

		// ###########################################################

		&-body {
			flex: auto;
		}
	}

	// ###########################################################

	.editor-text {
		margin-top: 32px;
		color: $cgp_blue_dark;

		// ###########################################################

		strong {
			font-weight: 600;
			color: $cgp_pink;
		}

		// ###########################################################

		a {
			text-decoration: underline;
			color: $cgp_blue_dark;
			font-weight: 700;

			&:hover {
				color: $cgp_bermuda;
			}
		}
	}

	// ###########################################################

	form {
		margin-top: 25px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 22px 12px;
		justify-items: center;

		// Mobile
		@media #{$breakpoint-xs-only} {
			grid-template-columns: 1fr;
			grid-gap: 15px;
		}
	}

	// ###########################################################

	.mod-input {
		margin-bottom: 0;
	}

	// ###########################################################

	.btn,
	.checkbox {
		// Tablet P and Up
		@media #{$breakpoint-sm-up} {
			grid-column: span 2;
		}
	}

	// ###########################################################

	.title--h1 {
		@extend %fontsize40;
	}
}
