// ##################################################
// Button
// ##################################################

@import '../../../scss/import';

// Style
.btn {
	position: relative;
	cursor: pointer;
	font-size: 15px;
	font-weight: 600;
	line-height: 1.5;
	padding: 8px 24px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: $cgp_white;
	text-align: center;
	border: 0;
	min-width: 180px;
	min-height: 48px;
	border-radius: 40px;
	background-color: $cgp_pink;
	transition: background-color 0.3s ease, color 0.3s ease;

	// ##################################################

	i,
	span {
		position: relative;
		z-index: 4;
	}

	// ##################################################

	.icon {
		margin-left: 15px;
		opacity: 0.5;

		&::before {
			background-color: $cgp_white;
		}
	}

	// ##################################################
	// Hover/Focus

	&:hover {
		background-color: $cgp_bermuda;
		color: $cgp_white;

		i::before {
			background-color: $cgp_white;
		}
	}

	// ##################################################

	&--secondary {
		background-color: $cgp_bermuda;

		&:hover {
			background-color: $cgp_pink;
		}
	}

	// ##################################################

	&--icon {
		text-align: left;
		justify-content: space-between;
		padding: 8px 22px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 8px 18px;
		}
	}
}
