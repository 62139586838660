// ##################################################
// Margin
// ##################################################

@import '../../../scss/import';

// Style
.margin {
	display: block;
	width: 100%;

	// ##################################################

	&--200 {
		height: $space200_lg;

		// Tablet L
		@media #{$breakpoint-md-only} {
			height: $space200_md;
		}

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: $space200_sm;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: $space200_xs;
		}
	}

	// ##################################################

	&--176 {
		height: 176px;

		// Tablet L
		@media #{$breakpoint-md-only} {
			height: 120px;
		}

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: 100px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 60px;
		}
	}

	// ##################################################

	&--160 {
		height: $space160_lg;

		// Tablet L
		@media #{$breakpoint-md-only} {
			height: $space160_md;
		}

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: $space160_sm;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: $space160_xs;
		}
	}

	// ##################################################

	&--120 {
		height: $space120_lg;

		// Tablet L
		@media #{$breakpoint-md-only} {
			height: $space120_md;
		}

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: $space120_sm;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: $space120_xs;
		}
	}

	// ##################################################

	&--100 {
		height: $space100_lg;

		// Tablet L
		@media #{$breakpoint-md-only} {
			height: $space100_md;
		}

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: $space100_sm;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: $space100_xs;
		}
	}

	// ##################################################

	&--80 {
		height: $space80_lg;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: $space80_sm;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: $space80_xs;
		}
	}

	// ##################################################

	&--70 {
		height: $space70_lg;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: $space70_sm;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: $space70_xs;
		}
	}

	// ##################################################

	&--60 {
		height: $space60_lg;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: $space60_sm;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: $space60_xs;
		}
	}

	// ##################################################

	&--50 {
		height: 50px;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: 40px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 32px;
		}
	}

	// ##################################################

	&--40 {
		height: $space40_lg;

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: $space40_xs;
		}
	}

	// ##################################################

	&--32 {
		height: $space32_lg;

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: $space32_xs;
		}
	}

	// ##################################################

	&--24 {
		height: $space24_lg;

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: $space24_xs;
		}
	}

	// ##################################################

	&--20 {
		height: $space20_lg;
	}

	// ##################################################

	&--16 {
		height: $space16_lg;
	}

	// ##################################################

	&--12 {
		height: $space12_lg;
	}

	// ##################################################

	&--8 {
		height: $space8_lg;
	}
}
