// ##################################################
// BACKGROUNDS
// ##################################################

.cover-bg {
	@extend %abs_pos_fullwidth;
	background-size: cover;
	background-position: center center;

	img {
		display: none;
	}
}

.covered {
	@extend %abs_pos_fullwidth;
	max-height: inherit;
}

.align-self-center {
	align-self: center;
}

.bg {
	&--blue {
		background-color: $cgp_blue;
		color: $cgp_white;

		// ##################################################

		.title {
			color: inherit;
		}
	}
}

// ##################################################
// TEXT ALIGN
// ##################################################

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

// ##################################################
// SCREEN READER
// ##################################################

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

// ##################################################

.button {
	@extend %reset_button;
}

// ##################################################
// TEXT
// ##################################################

.text {
	// ##################################################

	&--12 {
		@extend %fontsize12;
	}

	// ##################################################

	&--14 {
		@extend %fontsize14;
	}

	// ##################################################

	&--15 {
		@extend %fontsize15;
	}

	// ##################################################

	&--16 {
		@extend %page_text;
	}

	// ##################################################

	&--18 {
		@extend %fontsize18;
	}

	// ##################################################

	&--24 {
		@extend %fontsize24;
	}

	// ##################################################

	&--30 {
		@extend %fontsize30;
	}

	// ##################################################

	&--40 {
		@extend %fontsize40;
	}

	// ##################################################

	&--80 {
		@extend %fontsize80;
	}

	// ##################################################

	&--bd {
		font-weight: 700;
	}

	// ##################################################

	&--sb {
		font-weight: 600;
	}

	// ##################################################

	&--pink {
		color: $cgp_pink;
	}

	// ##################################################

	&--uppercase {
		text-transform: uppercase;
	}
}

.stretched-link {
	&::before {
		@extend %content;
		z-index: 3;
	}
}

.p-relative {
	position: relative;
}

// ##################################################
// VISIBILITY
// ##################################################

.hide {
	display: none;
}

.show {
	display: block;
}

.d-flex {
	display: flex;
}

.d-flex-cc {
	@extend %flex_center_center;
}

.pad-0 {
	padding: 0;
}

.f-auto {
	flex: auto;
	display: flex;
	flex-direction: column;
}

.card {
	width: 100%;
	display: block;
	box-shadow: 0 20px 30px rgba($cgp_black, 0.3);
	border-radius: 15px;
	background-color: $cgp_white;

	// Mobile
	@media #{$breakpoint-xs-only} {
		border-radius: 10px;
		box-shadow: 0 10px 15px rgba($cgp_black, 0.15);
	}
}
