// **************************************************
// Input
// **************************************************

@import '../../../scss/import';

.mod-input {
	margin-bottom: 12px;
	width: 100%;
	height: 48px;
	border-radius: 24px;
	position: relative;
	font-size: 14px;
	color: $cgp_blue;
	line-height: normal;
	border: 1px solid #d8d8d8;
	transition: border-color 0.3s ease;

	// ##################################################

	&__label {
		margin-bottom: 0;
		position: absolute;
		z-index: 12;
		display: inline-block;
		top: 14px;
		left: 24px;
		pointer-events: none;
		user-select: none;
		transition: opacity 0.3s ease, color 0.3s ease;

		// stylelint-disable-next-line
		.#{$class_validation_failed} & {
			color: $cgp_validation_failed;
		}
	}

	// ##################################################

	&__inner {
		height: 100%;
	}

	// ##################################################

	&__field {
		position: relative;
		width: 100%;
		height: 100%;
		border: none;
		outline: none;
		background-color: transparent;
		z-index: 1;
		padding: 8px 24px;
		bottom: 0;

		// Tablet
		@media #{$breakpoint-md-down} {
			padding: 0 12px;
		}

		// ###########################################################

		&::placeholder {
			color: $cgp_blue;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			color: $cgp_blue;
			opacity: 1;
		}

		&::-ms-input-placeholder {
			color: $cgp_blue;
			opacity: 1;
		}

		// ###########################################################

		// stylelint-disable-next-line
		.#{$class_validation_failed} &,
		&.#{$class_validation_failed} {
			color: $cgp_validation_failed;
		}
	}

	// ##################################################

	textarea {
		resize: none;
		height: 200px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 160px;
		}
	}

	// ###########################################################

	// stylelint-disable-next-line
	&.#{$class_focused} {
		border-color: $cgp_pink;

		// ###########################################################

		// stylelint-disable-next-line
		.mod-input__label {
			opacity: 0;
		}
	}

	// ###########################################################

	// stylelint-disable-next-line
	&.#{$class_validation_failed} {
		margin-bottom: 38px;
		border-color: $cgp_validation_failed;
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_disabled} {
		pointer-events: none;
		opacity: 0.5;
		background-color: rgba(0, 0, 0, 0.05);
	}
}

// ###########################################################

.validation-message {
	display: none;
	position: absolute;
	right: 0;
	top: 100%;
	color: $cgp-validation-failed;
	padding: 0 16px;
	font-size: 12px;
	margin-top: 8px;

	// stylelint-disable-next-line
	.#{$class_validation_failed} & {
		display: block;
	}
}
