//*******************************************************************************
// 1.0 - GENERAL
//*******************************************************************************

html,
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Text selection styling
::selection {
	background-color: $cgp_blue;
	color: $cgp_white;
}

// Modal window - prevent scrolling
body {
	@extend %page_text;
	color: $cgp_blue;
	font-family: $font_primary;
	background-color: $cgp_body_bg;
	position: relative;
	height: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: $vh_100;
	align-items: flex-start;
}

// Prevents text selection styling
.noselect,
.noselect * {
	user-select: none;
}

ul,
ol {
	list-style: none;
	margin: 0;
	padding: 0;
}

//*******************************************************************************
// 2.0 - GENERAL : Text styles
//*******************************************************************************

// Everything inside will inherit text style from parent
.text,
.title,
.title-main {
	// ##################################################

	* {
		@extend %inherit_textstyles;
	}
}

// Paragraphs
p {
	margin: 0;
}

// Links
a {
	color: inherit;
	text-decoration: none;
	@include transition(color 0.3s ease, border 0.3s ease);

	&:focus-visible {
		@extend %focus_visible;
	}
}

// Headings
@for $i from 1 through 6 {
	// stylelint-disable-next-line
	h#{$i} {
		font-weight: inherit;
		font-size: inherit;
		margin: 0;
	}
}

:focus {
	outline: none;
}

//*******************************************************************************
// 3.0 - GENERAL : Images
//*******************************************************************************

// General image
img {
	margin: 0;
	max-height: 100%;
	max-width: 100%;
	height: auto;
	width: auto;
	object-fit: cover;
}

// Figure (nested image for caption)
figure {
	// ##################################################
	img {
		margin: 0;
	}
}

//*******************************************************************************
// 4.0 - GENERAL : Section
//*******************************************************************************

.sc-general {
	position: relative;
	width: 100%;
}

//*******************************************************************************
// 6.0 GENERAL : Form
//*******************************************************************************

form select::-ms-expand {
	display: none;
}

input,
select,
textarea {
	box-shadow: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

// Ipad and mobile devices
input,
textarea {
	-webkit-appearance: none;
	border-radius: 0;
}

// Remove default red border for HTML5 validation
input:invalid {
	outline: none;
}

// Removes "clear field" X buttons in IE
textarea::-ms-clear,
input::-ms-clear {
	display: none;
}

button {
	@extend %reset_button;
}

hr {
	border: 0;
	height: 1px;
}

blockquote {
	margin: 0;
}

main {
	width: 100%;
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
}
