// **************************************************
// Title
// **************************************************

@import '../../../scss/import';

// Style
.title {

	// H1 Title
	&--h1 {
		@extend %title_h1;
	}

	// ##################################################

	// H2 Title
	&--h2 {
		@extend %title_h2;
	}

	// ##################################################

	// H3 Title
	&--h3 {
		@extend %title_h3;
	}

	// ##################################################

	// H4 Title
	&--h4 {
		@extend %title_h4;
	}

	// ##################################################

	// H5 Title
	&--h5 {
		@extend %title_h5;
	}

	// ##################################################

	// H6 Title
	&--h6 {
		@extend %title_h6;
	}
}
