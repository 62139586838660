//**********************************************************
// EDITOR TEXT
//**********************************************************

@import '../../../scss/import';

.editor-text {
	.container {
		.container & {
			max-width: 100%;
			padding: 0;
		}
	}

	// ##################################################

	@for $i from 1 through 6 {
		// stylelint-disable-next-line
		h#{$i} {
			margin-bottom: 22px;

			// stylelint-disable-next-line
			* + & {
				margin-top: 40px;
			}

			// stylelint-disable-next-line
			&:first-child {
				margin-top: 0;
			}

			// stylelint-disable-next-line
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	// ##################################################

	// H1 Title
	h1 {
		@extend %title_h1;
	}

	// ##################################################

	// H2 Title
	h2 {
		@extend %title_h2;
	}

	// ##################################################

	// H3 Title
	h3 {
		@extend %title_h3;
	}

	// ##################################################

	// H4 Title
	h4 {
		@extend %title_h4;
	}

	// ##################################################

	// H5 Title
	h5 {
		@extend %title_h5;
	}

	// ##################################################

	// H6 Title
	h6 {
		@extend %title_h6;
		font-size: 18px;
	}

	// ##################################################
	// General text elements

	p,
	ul,
	ol,
	img,
	figure,
	table {
		margin: 28px 0;

		// ##################################################

		&:first-child {
			margin-top: 0;
		}

		// ##################################################

		&:last-child {
			margin-bottom: 0;
		}

		// ##################################################

		a {
			color: $cgp_blue;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	// ##################################################
	// Images

	// Figure (nested image for caption)
	figure {
		img {
			margin: 0;
		}
	}

	// ##################################################
	// List styles

	ul,
	ol {
		list-style: none;
		margin-bottom: 38px;

		li {
			position: relative;

			& + li {
				margin-top: 16px;
			}
		}
	}

	// ##################################################

	ul {
		li {
			padding-left: 40px;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				width: 20px;
				height: 2px;
				background-color: $cgp_blue;
				top: 10px;
			}
		}
	}

	// ##################################################

	ol {
		counter-reset: ol_text_counter;

		// ##################################################

		li {
			padding-left: 1.75em;
			counter-increment: ol_text_counter;

			// ##################################################

			&:before {
				content: counter(ol_text_counter) '.';
				font-size: inherit;
				position: absolute;
				left: 0;
				color: $cgp_blue;
				@extend %inherit_textstyles;
			}
		}
	}

	// ##################################################
	// Tables

	table {
		border: 1px solid;
		width: 100%;

		// ##################################################

		tr {
			border-bottom: 1px solid;
		}

		// ##################################################

		th,
		td {
			padding: 0.5em;

			&:not(:first-child) {
				border-left: 1px solid;
			}
		}
	}

	// ##################################################
	// Blockquote

	blockquote {
		p {
			margin: 0 !important;
		}
	}

	// ##################################################
}
