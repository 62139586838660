// **************************************************
// Quiz Box
// **************************************************

@import '../../../scss/import';

.quiz-box {
	max-width: 568px;
	padding: 82px 65px 30px;
	position: relative;
	text-align: center;

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 62px 25px 30px;
	}

	// ##################################################

	&__number {
		width: 112px;
		height: 112px;
		box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
		background-color: $cgp_white;
		@extend %backface;
		border-radius: 100%;
		color: $cgp_pink;
		padding: 4px;
		position: absolute;
		left: 50%;
		top: -20px;
		transform: translate(-50%, -50%);

		// Tablet P only
		@media #{$breakpoint-sm-only} {
			font-size: 80px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			width: 80px;
			height: 80px;
			top: 0;
		}
	}

	// ##################################################

	&__question {
		color: $cgp_blue;
		font-weight: 400;
	}

	// ##################################################

	&__options {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 16px;
		max-width: 288px;
		width: 100%;
		margin: 34px auto 0;

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 100%;
			margin-top: 20px;
		}

		// Mobile xs
		@media (max-width: 360px) {
			grid-template-columns: 1fr;
		}
	}

	// ##################################################

	.radio-button {
		min-width: 1px;
		width: 100%;
		height: 100%;

		&__label {
			height: 100%;
		}
	}

	// ##################################################

	&__time-counter {
		margin-top: 40px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 30px;
		}
	}

	// ##################################################

	&__time {
		font-weight: 600;
		color: $cgp_pink;
	}

	// ##################################################

	&__progress-bar {
		max-width: 154px;
		width: 100%;
		margin: 12px auto 0;
		position: relative;
		height: 6px;
		border-radius: 3px;
		background-color: #161b4f;
		@extend %backface;

		// ##################################################

		&-line {
			@extend %abs_pos_fullwidth;
			background-color: $cgp_bermuda;
			transition: width 0.3s ease;
		}
	}

	// ##################################################

	&__footer {
		margin-top: 28px;

		// ##################################################

		.editor-text {
			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 16px;
			}

			a {
				font-weight: $semibold_weight;
				color: $cgp_bermuda;
				text-decoration: underline;

				&:hover {
					color: $cgp_pink;
				}
			}
		}
	}

	// ##################################################

	&__logo {
		max-width: 170px;
		width: 100%;
		margin: 30px auto 0;

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 150px;
			margin-top: 20px;
		}
	}
}
