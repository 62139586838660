// ##################################################
// Grids
// ##################################################

$css_grid: false !default; // Use CSS Grid (true) or Flexbox (false)
$base-font-size: 16px !default;


// ##################################################
// Main Fonts
// ##################################################

$font_primary:				    'Work Sans', sans-serif;

// ##################################################
// Colors
// ##################################################

// NEUTRAL COLORS
$cgp_white:									#fff;
$cgp_black:									#000;
$cgp_blue:									#151c4f;
$cgp_blue_dark:								#161b4f;
$cgp_pink:									#e5148c;
$cgp_bermuda:								#4ad5cf;
$cgp_gray:									#c4c4c4;
$cgp_gray_light:							#d0d0d0;
$cgp_border:								#707070;
$cgp_body_bg:								#ebebeb;
$cgp_validation_failed:						#ff3636;


// ##################################################
// Z-index
// ##################################################

$zindex_modal:          						999;
$zindex_cookie_notice:  						998;
$zindex_lotric_group:  						    101;
$zindex_header:        						    100;


// ##################################################
// 100vh Height
// ##################################################

$vh_100: calc(var(--vh, 1vh) * 100);


// ##################################################
// Class names (read from config JSON)
// ##################################################

// Usage: body.#{$class_open_menu} {};

$class_active:								"is-active";
$class_open:								"is-open";
$class_open_menu:							"is-open-menu";
$class_selected:							"is-selected";
$class_scrolled:							"is-scrolled";
$class_focused:								"is-focused";
$class_hidden:								"is-hidden";
$class_failed:								"is-failed";
$class_disabled:							"is-disabled";
$class_validation_failed:					"is-validation-failed";
$class_validation_passed:					"is-validation-passed";
$class_play:								"is-playing";


// ##################################################
// Font weight
// ##################################################

$extra_light_weight:						100;
$light_weight: 								200;
$book_weight: 					    		300;
$medium_weight: 							500;
$semibold_weight: 							600;
$bold_weight: 								700;
$black_weight: 								900;


// ##################################################
// Breakpoints
// ##################################################

$mobile_xs:									380px;


// ##################################################
// Spacings
// ##################################################

// 200px
$space200_lg: 200px;
$space200_md: 140px;
$space200_sm: 100px;
$space200_xs: 80px;

// 160px
$space160_lg: 160px;
$space160_md: 100px;
$space160_sm: 80px;
$space160_xs: 40px;

// 120px
$space120_lg: 120px;
$space120_md: 80px;
$space120_sm: 60px;
$space120_xs: 40px;

// 100px
$space100_lg: 100px;
$space100_md: 60px;
$space100_sm: 40px;
$space100_xs: 32px;

// 80px
$space80_lg: 80px;
$space80_sm: 40px;
$space80_xs: 32px;

// 70px
$space70_lg: 70px;
$space70_sm: 40px;
$space70_xs: 32px;

// 60px
$space60_lg: 60px;
$space60_sm: 32px;
$space60_xs: 24px;

// 40px
$space40_lg: 40px;
$space40_xs: 24px;

// 32px
$space32_lg: 32px;
$space32_xs: 24px;

// 24px
$space24_lg: 24px;
$space24_xs: 20px;

// 20px
$space20_lg: 20px;

// 16px
$space16_lg: 16px;

// 12px
$space12_lg: 12px;

// 8px
$space8_lg: 8px;
