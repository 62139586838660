// **************************************************
// Checkbox
// **************************************************

@import '../../../scss/import';

// Style
.checkbox {
	user-select: none;
	font-size: 12px;
	line-height: 1;
	color: $cgp_blue;

	// ##################################################

	&__label {
		position: relative;
		padding-left: 24px;
		width: 100%;
		cursor: pointer;
		margin: 0;
		display: block;
		transition: color 0.3s ease;

		// ##################################################

		&::before,
		&::after {
			content: '';
			width: 12px;
			height: 12px;
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 100%;
		}

		// ##################################################

		&::before {
			border: 1px solid #161b4f;
			transition: background-color 0.3s ease, border-color 0.3s ease;
		}

		// ##################################################

		&::after {
			height: 6px;
			width: 6px;
			top: 3px;
			left: 3px;
			background-color: $cgp_bermuda;
			opacity: 0;
			transform: scale(0.5);
			transition: opacity 0.3s ease, transform 0.3s ease;
		}
	}

	// ##################################################

	input {
		position: absolute;
		width: 1px;
		height: 1px;
		opacity: 0;

		// ##################################################

		&:checked ~ label {
			color: $cgp_black;

			// ##################################################

			&::before {
				border-color: $cgp_black;
			}

			// ##################################################

			&::after {
				opacity: 1;
				transform: scale(1);
			}
		}
	}

	// ##################################################
	// Without label

	&.hide-label {
		width: 12px;
		height: 12px;
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_validation_failed} {
		// stylelint-disable-next-line
		.checkbox__label {
			color: $cgp_validation_failed;

			// stylelint-disable-next-line
			&::before {
				border-color: $cgp_validation_failed;
			}
		}
	}
}
